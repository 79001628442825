import React, {useEffect, useState} from 'react';
import './App.scss';
import {
  BrowserRouter,
  Redirect,
  Switch,
  Route, useLocation,
} from "react-router-dom";
import HomePage from "./Components/HomePage/HomePage";
import NotFound from "./Components/NotFound/NotFound";
import Header from "./Components/Common/Header/Header";
import Footer from "./Components/Common/Footer/Footer";
import Brand from "./Components/Brand/Brand";
import { ThemeProvider } from 'styled-components';
import { lightTheme, darkTheme } from './theme';
import { GlobalStyles } from './global';
import {carBrands} from "./AppConstants";
import NewCar from "./Components/NewUsedCar/NewUsedCar";
import CarList from "./Components/CarList/CarList";
import BookingSystem from "./Components/BookingSystem/BookingSystem";

import {getBrands, getFooterData} from "./Components/HTTPS/apisInterface";
import Form from "./Components/Form/Form";
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module'
import FleetSelector from './Components/FleetSelector';

const tagManagerArgs = {
  gtmId: 'GTM-MHLT7G6'
}


function App() {

  const [theme, setTheme] = useState('light');
  const [brands,setBrands] = useState([]);
  const [homePageText,setHomePageText] = useState(null);
  const [currentBrand,setCurrentBrand] = useState(null);
  const [footerBrandData,setFooterBrandData]= useState(null);
  const [isRedirectURL,setIsRedirectUrl] = useState(null);

  const getCurrentBrandData = (location,locationSearch = null)=>{
    setIsRedirectUrl(new URLSearchParams(locationSearch).get('car_url'))
    location = location.substr(1,).split('/');
    const currentBrand = brands.filter(item=>location[0] === item.name.toLowerCase());

    if(currentBrand.length > 0){
      currentBrand[0].condition = location[1];
      currentBrand[0].link = currentBrand[0].name.toLowerCase();

      if(location.length > 3){
        currentBrand[0].currentCarName = location[3];
      }

      if(location.length >= 3){
        let currentExactLocation = currentBrand[0].locations.filter(item=>{
          return item.permalink === location[2];
        })
        if(currentExactLocation.length === 0 && location[1] === "fleet"){
          for(let i = 0;i < brands.length;i++){
            if(brands[i].name == "MINI"){
              currentExactLocation =  brands[i].locations.filter(item=>item.permalink === location[2])
            }
          }
        }
        if(currentExactLocation.length === 0 ){
          return null;
        }
        currentBrand[0].currentLocation = currentExactLocation[0];
        currentBrand[0].currentLocationFullName = currentExactLocation[0].name;
      }
      setCurrentBrand(currentBrand[0]);
      if(footerBrandData === null){
        getFooterData(currentBrand[0].name).then(response=>{
          setFooterBrandData(response.data);
        });
      }

      return currentBrand[0];
    }
    else{
      setCurrentBrand(null);
      return null;
    }

  }


  useEffect(()=>{
    TagManager.initialize(tagManagerArgs);
    //UA-131485799-4
    ReactGA.initialize('UA-XXXXXX');
    getBrands().then(res=>{
      setBrands(res.data.brands);
      setHomePageText(res.home_page_text);
    })
  },[])

  return (
     <React.Fragment>
       {brands.length > 0 ?  <ThemeProvider theme={theme === 'light' ? lightTheme : darkTheme}>
         <GlobalStyles />
         <BrowserRouter>
           <Header brand={currentBrand}/>
           <Route
              render={({location})=>{
                const { pathname } = location;
                if(pathname.includes(carBrands.mini.link)){
                  setTheme('dark')
                }
                else{
                  setTheme('light')
                }
                return (
                   <Switch location={location}>
                     <Route exact path="/" render={({location})=>{
                       ReactGA.pageview(window.location.pathname + window.location.search);
                       getCurrentBrandData(location.pathname,location.search);
                       setFooterBrandData(null);
                       return <HomePage reactGA={ReactGA} homePageText={homePageText} brands={brands}/>;
                     }}>
                     </Route>
                     <Route  exact path="/:brandType" render={ ({location})=>{
                       ReactGA.pageview(window.location.pathname + window.location.search);
                       const brand = getCurrentBrandData(location.pathname,location.search);
                       if(brand === null) return <NotFound/> ;
                       return <Brand reactGA={ReactGA} switchTheme={setTheme} brand={brand} />;
                     }}>
                     </Route>
                     <Route exact path="/:brandType/fleetwarning" render={ ({location})=>{
                       //This route intercepts fleet path to render "warning" page
                       const brand = getCurrentBrandData(location.pathname,location.search);
                       if(brand === null || brand === undefined) return  <NotFound/>;
                         let fleetLocations = null;
                           for(let i = 0; i < brands.length;i++){
                             if(brands[i].name === "MINI"){
                               fleetLocations = brands[i].locations;
                             }
                           }
                         return <FleetSelector reactGA={ReactGA} fleetLocations={fleetLocations} brandCondition={brand.condition}  brand={brand}/>;

                     }}/>
                     <Route exact path="/:brandType/:brandCondition" render={ ({location})=>{
                       ReactGA.pageview(window.location.pathname + window.location.search);
                       const brand = getCurrentBrandData(location.pathname,location.search);
                       if(brand === null || brand === undefined) return  <NotFound/>;
                       if(brand.condition === "new" || brand.condition === "used" || brand.condition === 'fleet'){
                         return <NewCar reactGA={ReactGA} brandCondition={brand.condition}  brand={brand}/>;
                       } else {
                         return <NotFound/>;
                       }
                     }}/>

                     <Route  exact path="/:brandType/:brandCondition/:brandLocation" render={ ({location})=>{
                       ReactGA.pageview(window.location.pathname + window.location.search);
                       const brand = getCurrentBrandData(location.pathname,location.search);
                       if(brand === null || brand === undefined) return <NotFound/>;

                       if(brand.condition === "new" || brand.condition === "used"){
                         if(isRedirectURL !== null){
                           return <Redirect to={location.pathname+`/aanbod${brand.link.toUpperCase()}${location.search}`}/>
                         }
                         return <CarList reactGA={ReactGA} brandCondition={brand.condition}  brand={brand} location={brand.currentLocation}/>;
                       } else if (brand.condition ==="fleet") {
                        return <Redirect to={location.pathname+`/nvt`}/>
                       } else{
                         return <NotFound/>
                       }
                     }}/>
                     <Route  exact path="/:brandType/:brandCondition/:brandLocation/:carName" render={ ({location})=>{
                       ReactGA.pageview(window.location.pathname + window.location.search);
                       const brand = getCurrentBrandData(location.pathname,location.search);
                       if(brand === null || brand === undefined) return <NotFound/>;
                       if(isRedirectURL !== null){
                         location.state = {};
                         location.state.carData = {};
                       } else if (brand.currentCarName === 'nvt') {
                         location.state = { carData : { name : 'nvt' } }
                       }
                       
                       return <BookingSystem reactGA={ReactGA} brandCondition={brand.condition} carData={location.state ? location.state.carData : {}} location={brand.currentLocation} brand={brand} />
                     }}/>
                     <Route  exact path="/:brandType/:brandCondition/:brandLocation/:carName/booking" render={ ({location})=>{
                       ReactGA.pageview(window.location.pathname + window.location.search);
                       const brand = getCurrentBrandData(location.pathname,location.search);
                       if(brand === null || brand === undefined) return <NotFound/>;
                       if(location.state === undefined) {return <NotFound/>;}

                       return <Form reactGA={ReactGA} urlSearchParam={isRedirectURL} brandCondition={brand.condition} {...location.state}/>
                     }}/>
                     <Route path="*" component={NotFound}/>

                   </Switch>)
              }}
           />
           <Footer socialMedia={currentBrand} brand={footerBrandData} theme={theme}/>
         </BrowserRouter>
       </ThemeProvider>:''}
     </React.Fragment>
  );
}

export default App;
